import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { enUS, th } from "date-fns/locale";
import dayjs from "dayjs";
import { Fragment, useRef, useState } from "react";
import { DateRange, Range as RDRRange, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useTranslation } from "react-i18next";
import { Button, Modal } from "src/main/components";
import { TRANSITION_IN_SLIDE_UP, TRANSITION_OUT_SLIDE_DOWN } from "src/main/contants";

const locales = {
  en: enUS,
  th: th,
};

export interface Range {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

interface DateRangePickerProps {
  expose: (range: Range) => void;
}

function DateRangePicker({ expose }: DateRangePickerProps) {
  const { t, i18n } = useTranslation();

  const [range, setRange] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
  });

  const buttonRef = useRef<"startDate" | "endDate">("startDate");
  const [step, setStep] = useState<0 | 1>(0);

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
    expose({
      startDate: range.startDate && dayjs(range.startDate).isValid() ? range.startDate : undefined,
      endDate: range.endDate && dayjs(range.endDate).isValid() ? range.endDate : undefined,
    });
  };
  const onOpen = (button: "startDate" | "endDate") => {
    buttonRef.current = button;
    setStep(button === "startDate" ? 0 : 1);
    setOpen(true);
  };

  const onClear = () => {
    setRange((prev) => ({
      startDate: undefined,
      endDate: undefined,
    }));
    expose({
      startDate: undefined,
      endDate: undefined,
    });
  };

  const onChange = (rbk: RangeKeyDict) => {
    const { key, ...rest } = rbk["queryRange"];
    // console.log(buttonRef.current, rest);
    setRange((prev) => {
      if (buttonRef.current === "endDate") {
        // if change endDate, data of component is ok
        const newRange = {
          startDate: rest.startDate && dayjs(rest.startDate).isValid() ? rest.startDate : undefined,
          endDate: rest.endDate && dayjs(rest.endDate).isValid() ? rest.endDate : undefined,
        };
        // console.log(newRange);
        expose(newRange);
        setOpen(false);
        return newRange;
      } else {
        // if change startDate => handle startDate > endDate
        const newRange = {
          ...prev,
          startDate: rest.startDate,
        };

        const startDate = dayjs(newRange.startDate);
        const endDate = dayjs(newRange.endDate);
        if (startDate.isAfter(endDate)) {
          if (buttonRef.current === "startDate") {
            newRange.endDate = undefined;
          }
        }

        buttonRef.current = "endDate";
        setStep(1);
        // console.log(newRange);
        expose({
          startDate: newRange.startDate && dayjs(newRange.startDate).isValid() ? newRange.startDate : undefined,
          endDate: newRange.endDate && dayjs(newRange.endDate).isValid() ? newRange.endDate : undefined,
        });

        return newRange;
      }
    });
  };

  return (
    <>
      <div className="flex w-full flex-row items-center justify-center gap-2">
        <div
          className={clsx(
            "flex w-full flex-row gap-2 text-sm text-white placeholder-white transition duration-150 ease-in-out sm:text-sm sm:leading-5",
          )}
        >
          <div
            className="grow rounded-md border-2 border-[rgba(87,85,148,0.7)] bg-[#0a0013] py-2 px-3"
            onClick={() => onOpen("startDate")}
          >
            {range.startDate && dayjs(range.startDate).isValid()
              ? dayjs(range.startDate).format("YYYY-MM-DD")
              : t("Start Date")}
          </div>
          <div
            className="grow rounded-md border-2 border-[rgba(87,85,148,0.7)] bg-[#0a0013] py-2 px-3"
            onClick={() => onOpen("endDate")}
          >
            {range.endDate && dayjs(range.endDate).isValid()
              ? dayjs(range.endDate).format("YYYY-MM-DD")
              : t("End Date")}
          </div>
        </div>
      </div>

      <Transition
        show={open}
        as={Fragment}
      >
        <Dialog onClose={onClose}>
          <Modal.Backdrop />

          <Transition.Child
            as="div"
            {...TRANSITION_IN_SLIDE_UP}
            {...TRANSITION_OUT_SLIDE_DOWN}
            className={clsx("fixed inset-0 z-[80] w-screen overflow-y-auto")}
          >
            <div className="flex min-h-full items-center justify-center p-4">
              <Dialog.Panel className={clsx("relative rounded-lg text-white shadow filter backdrop-blur")}>
                <DateRange
                  moveRangeOnFirstSelection={false}
                  showDateDisplay={false}
                  retainEndDateOnFirstSelection={true}
                  ranges={[{ ...(range as RDRRange), key: "queryRange" }]}
                  onChange={onChange}
                  focusedRange={[0, step]}
                  locale={locales[i18n.language as keyof typeof locales]}
                />
                <div className="flex flex-row justify-end gap-2 pt-2">
                  <Button
                    onClick={onClear}
                    variant="blue-violet"
                    size="lg"
                  >
                    {t("Reset")}
                  </Button>
                  <Button
                    outlined
                    onClick={onClose}
                    variant="blue-violet"
                    size="lg"
                  >
                    {t("Close")}
                  </Button>
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
}

export default DateRangePicker;
