import { AccountModel, AssetModel, BaseModel, ProductVariantModel } from "../types";

export interface UserInfoModel {
  balance_amt?: string | null;
  email?: string | null;
  first_time_login: boolean;
  full_name: string;
  mobile_no?: string | null;
  user_name: string;
}

export interface BrandModel extends BaseModel {
  // footer_logo_url: string;
  // footer_site_url: string;
  // id: number;
  // logo_url: string | null;
  // main_site_url: string | null;
  // name: string;
  // promotion_banners?: {
  //   data?: PromotionBannerModel[]
  // }
  name: string;
  description?: string;
  status: "active" | "inactive";
  siteUrl: string;
  sourceKey: string;
  icon?: {
    id: string;
    url: string;
    type: string;
  };
  domain?: string;
  sortPriority?: number;
}

export interface BannerModel {
  id: string;
  accountId: string;
  name: string;
  action: string | null;
  category: string | null;
  sortPriority: number | null;
  status: string;
  siteUrl: string | null;
  account?: AccountModel;
  image?: AssetModel | null;
  updatedAt: string;
}

export interface PromotionBannerModel extends BannerModel {}

export interface PopupBannerModel extends BannerModel {}

export interface DomainInfos {
  domain?: string | null;
  country_iso: string | null;
  deploymentType: string | null;
  isMain?: boolean | null;
  country_code: string | null;
  currency: string | null;
  defaultLang: string;
  brand?: string | null;
  mainUrl?: string | null;
}

export enum ExchangeTargetStatus {
  Active = "active",
  InActive = "inactive",
}

export interface ExchangeVariantModel extends ProductVariantModel {
  product?: {
    basePrice: string;
    minQuantity?: number;
    maxQuantity?: number;
  };
}

export interface ExchangeTargetModel {
  id: string;
  accountId?: string;
  name: string;
  status: ExchangeTargetStatus;
  currency: string;
  exchangeRate: string;
  sortPriority: number | null;
  icon: AssetModel | null;
  variants: ExchangeVariantModel[];
  brands?: BrandModel[];
}
