import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner } from "src/main/components";
import { Defaults } from "src/main/contants";
import { PopupBannerModel } from "src/main/models";
import { RootState } from "src/main/store";
import { setLaunchPopupBanner } from "src/main/store/slices";
import { UserState } from "src/main/store/user/slices";
import { getStorageItem } from "src/main/utils";
import { AuthState } from "../../../store/auth/slices";

interface PopupBannerProps {
  delayOnNext?: number;
}

const PopupBanner = ({ delayOnNext = 100 }: PopupBannerProps) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { popupBanners } = useSelector<RootState, UserState>((state) => state.user);
  const { self } = useSelector<RootState, AuthState>((state) => state.auth);
  const [stagedBanner, setStagedBanner] = useState<PopupBannerModel>();
  const bannerQueue = useRef<PopupBannerModel[]>([]);

  useEffect(() => {
    if (popupBanners && popupBanners.length) {
      bannerQueue.current = getNewPopupBanners([...popupBanners], self?.member?.id ?? "");
      setStagedBanner(bannerQueue.current.shift());
    }
  }, [popupBanners, self]);

  useEffect(() => {
    if (!!stagedBanner) setShowModal(true);
  }, [stagedBanner]);

  const goToNextBanner = () => {
    const nextBanner = bannerQueue.current.shift();
    if (nextBanner) {
      setStagedBanner(nextBanner);
    } else {
      // end of list
      dispatch(setLaunchPopupBanner({ show: false }));
    }
  };

  const closeHandler = (event?: React.MouseEvent) => {
    event?.preventDefault();
    markAsSeenBanner(stagedBanner!, self?.member?.id ?? "");
    setShowModal(false);
    setTimeout(() => {
      goToNextBanner();
    }, delayOnNext);
  };

  return (
    <Transition.Root
      show={showModal}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => closeHandler}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-50 bg-black/90" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden transition-all sm:my-8 sm:w-full sm:max-w-sm md:w-fit">
                <button
                  className="absolute right-2 top-2 z-10"
                  onClick={closeHandler}
                >
                  <XCircleIcon className="mx-auto h-6 w-6 text-white drop-shadow-lg" />
                </button>
                {!!stagedBanner && <Banner data={stagedBanner} />}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

/**
 * Popup banner seen history
 * New or updated popup banners will be filtered based on banner id and updated at
 * key: banner id + member id
 * value: banner updated at
 */
type PopupSeenHistory = {
  [key: string]: string;
};

const generateBannerHistoryKey = (banner: PopupBannerModel, memberId: string) => {
  return `${banner.id}#${memberId}`;
};

const getNewPopupBanners = (popupBanners: PopupBannerModel[], memberId: string) => {
  const seenHistory = getStorageItem<PopupSeenHistory>(Defaults.PopupBannerHistoryKey, {}, JSON.parse, "{}");
  if (seenHistory) {
    return popupBanners.filter((banner) => {
      const historyKey = generateBannerHistoryKey(banner, memberId);
      return !seenHistory[historyKey] || seenHistory[historyKey] !== banner.updatedAt;
    });
  }
  return popupBanners;
};

const markAsSeenBanner = (banner: PopupBannerModel, memberId: string) => {
  const seenHistory = getStorageItem<PopupSeenHistory>(Defaults.PopupBannerHistoryKey, {}, JSON.parse, "{}");
  const historyKey = generateBannerHistoryKey(banner, memberId);
  seenHistory[historyKey] = banner.updatedAt;
  localStorage.setItem(Defaults.PopupBannerHistoryKey, JSON.stringify(seenHistory));
};

export default PopupBanner;
