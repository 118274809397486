import { TFunction } from "i18next";
import moment from "moment";
import { getStorageItem } from "./storageHandler";

const domainExt = "www.";
export const checkWithOrWithoutWWW = (base: string, domainName: string) => {
  return base === domainName || domainExt + base === domainName;
};

export const convertUTCToLocal = (utcDateTime: string): moment.Moment => {
  const localDateTime = moment.utc(utcDateTime).local();
  return localDateTime;
};

const sortByName = (value: string | null) => {
  switch (value) {
    case "alphabetical_a_to_z":
      return 1;
    case "alphabetical_z_to_a":
      return 2;
    default:
      return 0;
  }
};

const sortByPoint = (value: string | null) => {
  switch (value) {
    case "point_low_to_high":
      return 1;
    case "point_high_to_low":
      return 2;
    default:
      return 0;
  }
};

const sortingCombination = (sortByName: number, sortByPrice: number): number => {
  const combinations: { [key: string]: number } = {
    "1-1": 3,
    "1-2": 6,
    "1-0": 2,
    "2-1": 5,
    "2-2": 4,
    "2-0": 8,
    "0-1": 1,
    "0-2": 7,
    "0-0": 0,
  };

  const combinationKey = `${sortByName}-${sortByPrice}`;
  return combinations[combinationKey] || 0;
};

export const getProductSortingId = (byName?: string, byPoint?: string): number => {
  const ifSortByPoint = sortByPoint(byPoint ?? null);
  const ifSortByName = sortByName(byName ?? null);
  const sortId = sortingCombination(ifSortByName, ifSortByPoint);
  return sortId;
};

interface sortKey {
  byName?: string;
  byPoint?: string;
}

interface SortData {
  length: number;
  data: sortKey | undefined;
}

export const getNoSortingRule = (): SortData => {
  try {
    const parsedData = getStorageItem("sort", {} as any, JSON.parse, "{}");
    if (!Object.keys(parsedData).length) {
      return {
        length: 0,
        data: undefined,
      };
    }
    return {
      length: Object.keys(parsedData).length,
      data: parsedData,
    };
  } catch (error) {}
  return {
    length: 0,
    data: undefined,
  };
};

export const formatPhoneNumber = (phoneNumber: string, countryCode: string | number) => {
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
  if (numericPhoneNumber.length === 11 && numericPhoneNumber.startsWith(countryCode + "")) {
    const formattedPhoneNumber = phoneNumber.slice(2).replace(/(\d{2})(\d{3})(\d{4})/, "0$1 $2 $3");

    return formattedPhoneNumber;
  } else if (numericPhoneNumber.length === 11 && numericPhoneNumber.startsWith("60")) {
    const formattedPhoneNumber = numericPhoneNumber.slice(2).replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
    return formattedPhoneNumber;
  } else if (numericPhoneNumber.length === 10 && numericPhoneNumber.startsWith("0")) {
    const formattedPhoneNumber = numericPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
    return formattedPhoneNumber;
  } else {
    // If it doesn't match the expected format, return the original input
    return phoneNumber;
  }
};

export const mobileNumberOnly = (phoneNumber: string, countryCode: string | number) => {
  return phoneNumber.replace(new RegExp(`^${countryCode}`), "0");
};

export const localizedPhoneNumber = (phoneNumber: string) => {
  // Remove any non-numeric characters
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

  console.log(numericPhoneNumber.length);
  console.log(numericPhoneNumber.startsWith("60"));

  // Check if the number starts with "60" and has a total length of 11 digits
  if (numericPhoneNumber.length === 11 && numericPhoneNumber.startsWith("6")) {
    // Remove the leading "60" and format the remaining digits
    const formattedPhoneNumber = numericPhoneNumber.slice(1).replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
    return formattedPhoneNumber;
  } else if (numericPhoneNumber.length === 11 && numericPhoneNumber.startsWith("6")) {
    // Remove the leading "60" and format the remaining digits
    const formattedPhoneNumber = numericPhoneNumber.slice(1).replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
    return formattedPhoneNumber;
  } else {
    // If it doesn't match the expected format, return the original input
    return phoneNumber;
  }
};

export const getBaseUrl = (): string => {
  const protocol: string = window.location.protocol;
  const hostname: string = window.location.hostname;
  const port: string = window.location.port;

  let baseUrl: string = `${protocol}//${hostname}`;

  if (port && port !== "80" && port !== "443") {
    baseUrl += `:${port}`;
  }

  return baseUrl;
};

export const getErrorMessage = (error: any, t: TFunction) => {
  return t(error?.response?.data?.message ?? error?.data?.error?.message ?? "Something went wrong");
};

export function formatDuration(durationInSeconds: number) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const mergeKeys = (...keys: (string | number)[]) => {
  return keys.join("_");
};
