import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import IcoLogout from "src/assets/IcoLogout";
import UserMenuList from "src/main/components/UserMenuList";
import auth from "src/main/store/auth";
import { DeployCountry } from "src/main/types";
import { getDeployEnv } from "src/main/utils";
// import { PointExchangeBanner } from "..";

function ProfilePointComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const currentEnv = getDeployEnv();

  function handleLogout() {
    dispatch(auth.slice.actions.logout());
  }

  return (
    <div className="w-full text-left">
      <div className="flex flex-col gap-6">
        {/* <PointExchangeBanner /> */}
        <div className="flex flex-col gap-2 font-bold text-[rgba(133,144,172,1)]">
          <p className="m-0 mt-4 w-80 text-xxs  font-bold uppercase not-italic leading-3 text-[#B8BECC]">
            {t("important section")}
          </p>
          <Link
            to="/order-history#order"
            relative="path"
          >
            <div
              className="gap-[30px]"
              onClick={() => {
                navigate("/order-history#order");
              }}
            >
              <UserMenuList
                type="TYPE"
                text="order history"
                selected={
                  location.pathname === "/profile" ||
                  (location.pathname === "/order-history" && location.hash === "#order")
                }
              />
            </div>
          </Link>
          <Link
            to="/order-history#point"
            relative="path"
          >
            <div className="gap-[30px]">
              <UserMenuList
                type="TYPE4"
                text="points history"
                selected={location.pathname === "/order-history" && location.hash === "#point"}
              />
            </div>
          </Link>
        </div>
        <div className="flex flex-col items-start gap-2 pb-20">
          <p className="m-0 mt-4 w-80 text-xxs  font-bold uppercase not-italic leading-3 text-[#B8BECC]">
            {t("account setting")}
          </p>
          <div className="flex w-full flex-col gap-2 font-normal text-white">
            <Link
              to="/change-password"
              relative="path"
            >
              <div className="w-full cursor-pointer  gap-[30px]">
                <UserMenuList
                  type="TYPE1"
                  text="manage password"
                  selected={location.pathname === "/change-password"}
                />
              </div>
            </Link>
            <Link
              to="/manage-address"
              relative="path"
            >
              <div className="w-full cursor-pointer  gap-[30px]">
                <UserMenuList
                  type="TYPE5"
                  text="manage address"
                  selected={location.pathname.startsWith("/manage-address")}
                />
              </div>
            </Link>
            {currentEnv.deployCountry === DeployCountry.TH && (
              <Link
                to="/change-language"
                relative="path"
              >
                <div className="w-full cursor-pointer  gap-[30px]">
                  <UserMenuList
                    type="TYPE2"
                    text="change language"
                    selected={location.pathname === "/change-language"}
                  />
                </div>
              </Link>
            )}
            <Link
              to="/faqs"
              relative="path"
            >
              <div className="w-full cursor-pointer  gap-[30px]">
                <UserMenuList
                  type="TYPE3"
                  text="faq"
                  selected={location.pathname === "/faqs"}
                />
              </div>
            </Link>
            <div className="mt-8 mb-4 h-[1px] w-full border-t-[1px] border-t-[#D2C1FF26]/[15%]"></div>
            <div
              className="flex w-full cursor-pointer items-center justify-center gap-1 self-stretch rounded-md p-2 hover:bg-[rgb(140_140_140/40%)] active:bg-[rgb(140_140_140/40%)]"
              onClick={handleLogout}
            >
              <p className="m-0 text-sm font-bold leading-4 text-white">{t("Log Out")}</p>
              <IcoLogout />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePointComponent;
