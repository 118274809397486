import React from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";
import IcoLogout from "src/assets/IcoLogout";
import Avatar from "../../components/Avatar";
// import BtnSmall from "../../components/BtnSmall/BtnSmall";
import UserMenuList from "../../components/UserMenuList";
import OrderHistoryContent from "../OrderHistory/components/OrderHistoryContent";

import { useDispatch } from "react-redux";
import { IcoSwap } from "src/assets";
import { Button, Page } from "src/main/components";
import { useTypedSelector } from "src/main/hooks";
import auth from "src/main/store/auth";
import { DeployCountry } from "src/main/types";
import { displayPhoneNumber, fNumber, getDeployEnv, Paths } from "src/main/utils";
import { ProfileView } from "src/main/views/component";
// import { PointExchangeBanner } from "../component";
// import IcoHeartRounded from "src/assets/IcoHeartRounded";

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let navigate: NavigateFunction = useNavigate();
  const currentEnv = getDeployEnv();

  const { self } = useTypedSelector((state) => state.auth);

  function handleLogout() {
    dispatch(auth.slice.actions.logout());
  }

  return (
    <Page paddingTop>
      <ProfileView
        title="order history"
        desktopOnly
      >
        <OrderHistoryContent />
      </ProfileView>
      <div className="w-max-md mx:auto flex h-full flex-col items-center gap-4 px-3 lg:hidden lg:px-96">
        <div className="text-left font-bold text-white">
          <div className="flex w-[360px] flex-col items-center gap-[18px]">
            <Avatar />
            {!!self?.member?.phone && (
              <div className="mb-3 flex w-80 flex-col items-center justify-center gap-1">
                <p className="m-0 text-lg leading-[18px]">***{displayPhoneNumber(self?.member?.phone).slice(-3)}</p>
              </div>
            )}
          </div>
        </div>
        <div className="w-full px-1">
          <div className="w-full rounded-lg bg-[radial-gradient(84.58%_106.92%_at_83.69%_0%,#26125C_0%,#170B37_100%)] p-3 [box-shadow:1px_1px_0px_0px_rgba(137,118,255,0.24)_inset]">
            <div className="flex justify-between w-full items-center gap-2 overflow-clip rounded-lg bg-[rgba(0,0,0,0.5)] pl-2 text-white">
              <div className="max-w-[calc(100%-131px)] grow overflow-hidden">
                <div className="flex flex-col items-start gap-1 py-3">
                  <div className="flex items-center justify-center gap-1.5 text-center font-bold">
                    <div className="relative h-[30px] w-[30px]">
                      <img
                        src="/images/icon/heart_icon_yellow.svg"
                        height={30}
                        width={30}
                        alt="points-icon"
                      />
                    </div>
                    <p className="m-0 text-2xl leading-6">{fNumber(self?.member?.primaryWallet?.balance, "floor")}</p>
                  </div>
                </div>
              </div>
              <div className="h-[54px]">
                <Button
                  onClick={() => {
                    navigate(Paths.Private.CashBack);
                  }}
                  iconPlacement="right"
                  className="h-[54px] mr-5 rounded-l-none"
                  variant="blue-violet"
                  size="md"
                  icon={<IcoSwap />}
                >
                  {t("cashBack")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-left lg:hidden">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2 px-3 font-bold">
            <p className="m-0 mt-4 w-80 text-xxs font-bold uppercase not-italic leading-3 text-[#B8BECC]">
              {t("important section")}
            </p>
            <div
              className="gap-[30px]"
              onClick={() => {
                navigate("/order-history#order");
              }}
            >
              <UserMenuList
                type="TYPE"
                text="order history"
              />
            </div>
            <div
              className="gap-[30px]"
              onClick={() => {
                navigate("/order-history#point");
              }}
            >
              <UserMenuList
                type="TYPE4"
                text="points history"
              />
            </div>
          </div>
          <div className="flex flex-col items-start gap-2 px-3 pb-20">
            <p className="m-0 mt-4 w-80 text-xxs font-bold uppercase not-italic leading-3 text-[#B8BECC]">
              {t("account setting")}
            </p>
            <div className="flex w-full flex-col gap-2 font-normal text-white">
              <div
                className="w-full cursor-pointer gap-[30px]"
                onClick={() => {
                  navigate("/change-password");
                }}
              >
                <UserMenuList
                  type="TYPE1"
                  text="Change Password"
                />
              </div>
              <div
                className="w-full cursor-pointer gap-[30px]"
                onClick={() => {
                  navigate("/manage-address");
                }}
              >
                <UserMenuList
                  type="TYPE5"
                  text="manage address"
                />
              </div>

              {currentEnv.deployCountry === DeployCountry.TH && (
                <div
                  className="w-full cursor-pointer gap-[30px]"
                  onClick={() => {
                    navigate("/change-language");
                  }}
                >
                  <UserMenuList
                    type="TYPE2"
                    text="change language"
                  />
                </div>
              )}
              <div
                className="w-full cursor-pointer gap-[30px]"
                onClick={() => {
                  navigate("/faqs");
                }}
              >
                <UserMenuList
                  type="TYPE3"
                  text="faq"
                />
              </div>
              <div className="mt-8 mb-4 h-[1px] w-full border-t-[1px] border-t-[#D2C1FF26]/[15%]"></div>
              <div
                className="flex w-full cursor-pointer items-center justify-center gap-1 self-stretch rounded-md p-2 active:bg-[rgb(140_140_140/40%)]"
                onClick={handleLogout}
              >
                <p className="m-0 text-sm font-bold leading-4 text-white">{t("Log Out")}</p>
                <IcoLogout />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Profile;
